import React, { useState, useEffect, ReactNode, FC } from 'react';
import { Image, ImageProps } from 'antd';
import SkeletonLoader from './SkeletonLoader';
import ExpandIcon from '../icons/ExpandIcon';

type Props = {
  src: string;
  alt: string;
  className?: string;
  hasPreview?: boolean;
  previewSrc?: string;
  width?: string | number;
  height?: string | number;
  errorWidget?: ReactNode;
  onFailure?: () => void;
} & ImageProps;

const ImageHandler: FC<Props> = ({
  src,
  alt,
  className,
  hasPreview,
  previewSrc,
  width,
  height,
  errorWidget,
  onFailure,
  ...rest
}: Props) => {
  const [source, setSource] = useState(src);
  const [hasError, setHasError] = useState(false);
  const [showPreviewImage, setShowPreviewImage] = useState(false);

  const onVisibleChange = (visible) => {
    setShowPreviewImage(visible);
  };

  useEffect(() => {
    setSource(src);
    setHasError(!src);
  }, [src]);

  if (hasError) {
    return <>{errorWidget}</>;
  }

  const content = () => {
    if (hasPreview && !hasError) {
      return (
        <div style={{ position: 'relative', height: '100%' }}>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => null}
            onClick={() => {
              setShowPreviewImage(true);
            }}
          >
            <ExpandIcon />
          </div>
          <Image
            src={source}
            alt={alt}
            className={className}
            width={width}
            height={height}
            placeholder={hasPreview ? <div /> : <SkeletonLoader />}
            preview={
              hasPreview
                ? {
                    visible: showPreviewImage,
                    maskClassName: 'previewMask hidden-div',
                    src: previewSrc || src,
                    onVisibleChange,
                  }
                : false
            }
            onError={() => {
              setHasError(true);
              onFailure?.();
            }}
            {...rest}
          />
        </div>
      );
    }

    return (
      <Image
        src={source}
        alt={alt}
        className={className}
        width={width}
        height={height}
        placeholder={<SkeletonLoader />}
        preview={
          hasPreview
            ? {
                visible: showPreviewImage,
                maskClassName: 'previewMask hidden-div',
                src: previewSrc || src,
                onVisibleChange,
              }
            : false
        }
        onError={() => {
          setHasError(true);
          onFailure?.();
        }}
        {...rest}
      />
    );
  };

  return content();
};

ImageHandler.defaultProps = {
  className: 'nft-img',
  hasPreview: false,
  width: '100%',
  height: '100%',
};

export default ImageHandler;
