import { Row } from 'antd';
import React, { FC } from 'react';
import { CHAINS } from '../../lib/constants';
import { BNBIcon } from '../../lib/Resources';

interface Props {
  price: string | number;
  currency?: string;
  title?: string;
  chain?: string;
  width?: number;
  height?: number;
  className?: string;
}

const ChainPriceTile: FC<Props> = ({
  chain,
  price,
  width,
  height,
  className,
  currency = '',
  title = '',
}: Props) => {
  const chainAsset = CHAINS[chain] || CHAINS.bsc;
  return (
    <Row align="middle" className={className}>
      <img
        src={chainAsset.icon || BNBIcon}
        width={width}
        height={height}
        alt="Asset logo"
      />

      <div className="chain-price">
        {title}
        {price} {currency}
      </div>
    </Row>
  );
};

ChainPriceTile.defaultProps = {
  height: 20,
  width: 20,
  chain: 'bsc',
};

export default ChainPriceTile;
