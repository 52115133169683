import React from 'react';

type Props = {
  position?: number;
  onChange?: () => void;
};

const RadioButton = ({ position, onChange }: Props) => {
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange();
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => null}
      className={`select-button ${position ? 'bg-blue' : ''}`}
      onClick={onClick}
    >
      {!!position && <span className="select-button-position">{position}</span>}
    </div>
  );
};

export default RadioButton;
