import React, { FC } from 'react';

interface Props {
  className?: string;
}

const LockIcon: FC<Props> = ({ className }: Props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect
      x="2.44946"
      y="6.41437"
      width="11.1009"
      height="8.65691"
      rx="2"
      stroke="#FFFFFF"
      strokeWidth="2"
    />
    <path
      d="M7.99992 9.92804V11.5577"
      stroke="#FFFFFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2472 3.62901C10.9492 1.86089 9.02483 1.06668 8.08491 1.06668C6.25262 1.05866 4.76083 2.53733 4.75281 4.37035V4.38493V5.95036"
      stroke="#FFFFFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

LockIcon.defaultProps = {
  className: 'lock-icon lock-icon-nft-detail-page',
};

export default LockIcon;
