import React, { FC } from 'react';

interface Props {
  className?: string;
}

const ExpandIcon: FC<Props> = ({ className }: Props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g filter="url(#filter0_b_6188_17114)">
      <rect width="40" height="40" rx="20" fill="black" fillOpacity="0.24" />
      <g clipPath="url(#clip0_6188_17114)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.6001 14H21.0001V12H28.0001V19H26.0001V15.4L22.0001 19.4L20.6001 18L24.6001 14ZM14 24.6L18 20.6L19.4 22L15.4 26H19V28H12V21H14V24.6Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_b_6188_17114"
        x="-24"
        y="-24"
        width="88"
        height="88"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="12" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_6188_17114"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_6188_17114"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_6188_17114">
        <rect x="12" y="12" width="16" height="16" rx="1" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

ExpandIcon.defaultProps = {
  className: 'expand-icon',
};

export default ExpandIcon;
