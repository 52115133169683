import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import { IsSafari } from '../lib/isBrowser';
import { VideoPlayButtonIcon } from '../lib/Resources';

interface Props {
  url: string;
  showControls?: boolean;
  autoplay?: boolean;
  urlThumbnail?: string;
  isSmallSize?: boolean;
}

export function VideoPlayer({
  url,
  showControls = false,
  autoplay = false,
  isSmallSize = false,
  urlThumbnail,
}: Props) {
  const playerRef = useRef(null);
  const type = 'video/mp4';

  useEffect(() => {
    const player = videojs(playerRef.current, {
      autoplay: autoplay || (!!showControls && !IsSafari({ mobile: true })),
      muted: true,
      controls: showControls,
      loop: true,
      inactivityTimeout: 0,
      controlBar: {
        fullscreenToggle: showControls,
        pictureInPictureToggle: false,
      },
      userActions: {
        doubleClick: showControls,
      },
      src: url,
      playsInline: true,
    });
    player.ready(() => {
      player.playsinline(true);
      player.volume(0.5);
      player.src({ src: url, type });
      setTimeout(
        () => {
          if (
            player &&
            (autoplay || showControls || !IsSafari({ mobile: true }))
          ) {
            const promise = player?.play();
            if (promise !== undefined) {
              promise.catch((error) => {
                // Autoplay was prevented.
                console.log(error);
              });
            }
          }
        },
        showControls ? 500 : 1_000,
      );
    });
    return () => {
      if (!player.isDisposed()) {
        player.dispose();
      }
    };
  }, []);

  const content = () => {
    if (isSmallSize) {
      return (
        <div data-vjs-player>
          <video
            ref={playerRef}
            className="video-js vjs-fill"
            playsInline
            webkit-playsinline="true"
            muted
            loop
            controls={showControls}
            poster={urlThumbnail}
          />
        </div>
      );
    }

    return (
      <div data-vjs-player>
        <video
          ref={playerRef}
          className="video-js vjs-fill"
          playsInline
          webkit-playsinline="true"
          muted
          loop
          controls={showControls}
          poster={urlThumbnail}
        />
        {!showControls && (
          <div className="video-player-mask">
            <div className="nft-img nft-video-play-button">
              <img
                className="nft-video-play-icon"
                src={VideoPlayButtonIcon}
                alt="Play button"
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return content();
}
