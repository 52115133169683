export function IsSafari({ mobile = false }: { mobile?: boolean }): boolean {
  if (!process.browser) {
    return false;
  }

  const agent = navigator?.userAgent.toLowerCase();
  const isMobile = mobile ? /(iPad|iPhone|iPod)/gi.test(agent) : true;

  return (
    isMobile && agent.indexOf('chrome') === -1 && agent.indexOf('safari') !== -1
  );
}
