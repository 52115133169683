import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ClockIcon } from '../../lib/Resources';
import { calculateLaunchpadTimeLeft } from '../../lib/Utils';
import { NftModel } from '../../lib/models/NftModel';

export function AuctionTimeLeftComponent({
  expirationDate,
  isSmallCard,
  onAuctionEnd,
  auction,
  isRelative = true,
  className = '',
  isFromNftDetailPage = false,
  shouldExpire = false,
}: {
  expirationDate: string;
  isSmallCard?: boolean;
  auction?: NftModel;
  onAuctionEnd?: (auction: NftModel) => void;
  isRelative?: boolean;
  className?: string;
  isFromNftDetailPage?: boolean;
  shouldExpire?: boolean;
}) {
  const [timeLeft, setTimeLeft] = useState('');
  const timer = useRef(null);

  useEffect(() => {
    const launchpadTimeLeft = calculateLaunchpadTimeLeft(expirationDate);
    setTimeLeft(launchpadTimeLeft.timeLeft);

    timer.current = setInterval(() => {
      setTimeLeft(calculateLaunchpadTimeLeft(expirationDate).timeLeft);
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearInterval(timer.current);
  });
  const { t } = useTranslation();

  const hasAuctionEnded = dayjs(expirationDate).isBefore(dayjs());

  useEffect(() => {
    if (hasAuctionEnded) {
      onAuctionEnd?.(auction);
    }
  }, [hasAuctionEnded]);

  if (shouldExpire && !dayjs(expirationDate).isBefore(dayjs())) {
    return (
      <div className={`countdown-timer ${className}`}>
        <img src={ClockIcon} alt="Clock icon" />
        {timeLeft}
      </div>
    );
  }

  if (shouldExpire && dayjs(expirationDate).isBefore(dayjs())) {
    return <div />;
  }

  if (!isRelative) {
    return (
      <div className={`countdown-timer ${className}`}>
        <img src={ClockIcon} alt="Clock icon" />
        {dayjs(expirationDate).isBefore(dayjs())
          ? t('Auction has ended')
          : timeLeft}
      </div>
    );
  }

  if (isFromNftDetailPage) {
    return (
      <div className="nft-card-detail-offer-timer">
        <img src={ClockIcon} alt="Clock icon" />
        {dayjs(expirationDate).isBefore(dayjs())
          ? t('Auction has ended')
          : `Ends in: ${timeLeft}`}
      </div>
    );
  }

  return (
    <div
      className={` ${
        isSmallCard
          ? 'auction-timer-countdown-small'
          : 'auction-timer-countdown'
      }`}
    >
      <div className={`countdown-timer ${className}`}>
        <img src={ClockIcon} alt="Clock icon" />
        {dayjs(expirationDate).isBefore(dayjs())
          ? t('Auction has ended')
          : timeLeft}
      </div>
    </div>
  );
}
