import React, { useReducer } from 'react';

import { useRecoilValue } from 'recoil';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import Router from 'next/router';

import { NftCardData, NftModel } from '../lib/models/NftModel';
import { formatNumber, isVideo, sleep } from '../lib/Utils';
import { connectionAccountState } from '../recoil/atoms';
import { EmptyIcon, NeutralVoteIcon, UpvoteIcon } from '../lib/Resources';
import { localCache } from '../lib/db/LocalCache';
import { VideoPlayer } from './VideoPlayer';
import UserAvatar from './ui/UserAvatar';
import { AuctionTimeLeftComponent } from './ui/AuctionTimeLeftComponent';
import ChainPriceTile from './ui/ChainPriceTile';
import RadioButton from './ui/RadioButton';
import { TListItem } from '../lib/@Types';
import HrefLink from './ui/HrefLink';
import LockIcon from './icons/LockIcon';
import ImageHandler from './ui/ImageHandler';

type IState = {
  refreshing: boolean;
  refreshed: boolean;
};

function NFTCardPrice({
  openForSale,
  nft,
}: {
  openForSale: boolean;
  nft: NftCardData;
}) {
  const { t } = useTranslation();

  if (openForSale || nft?.nft?.auction) {
    return (
      <ChainPriceTile
        chain={nft?.chain}
        price={Number(
          new BigNumber(nft?.nft?.price || nft.nftPrice).toFixed(4),
        ).toLocaleString()}
        className="nft-card-price-wrapper"
      />
    );
  }

  return <div>{t('Not On Sale 🔒')}</div>;
}

export const NftCard = ({
  nftCardData,
  hideUserAvatar = false,
  className = 'nft-card relative',
  selectable = false,
  selectionPosition,
  onAuctionEnd,
  onSelect,
}: {
  nftCardData: NftCardData;
  hideUserAvatar?: boolean;
  selectable?: boolean;
  selectionPosition?: number;
  className?: string;
  onAuctionEnd?: (auction: NftModel) => void;
  onSelect?: (nft: TListItem) => void;
}) => {
  const currentAccount = useRecoilValue(connectionAccountState);
  const [state, dispatch] = useReducer(
    (prev: IState, next: Partial<IState>) => ({ ...prev, ...next }),
    {
      refreshed: false,
      refreshing: false,
    },
  );

  const params = nftCardData.nft.isExternal ? '' : '';

  const href = !selectable ? `/nft/${nftCardData.nftBaseID}${params}` : null;

  function wasUpVoted() {
    return localCache.checkIfItHasUpvoted(
      currentAccount.address,
      nftCardData.nftTokenID,
    );
  }

  const onChange = () => {
    onSelect?.(nftCardData);
  };

  const onClick = async () => {
    if (selectable) {
      onChange();
      return;
    }

    await Router.push(href);
  };

  const onReloadImage = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch({ refreshing: true });
    await sleep(2_500);
    dispatch({ refreshed: true, refreshing: false });
    message.success('Metadata refreshed');
    return false;
  };

  return (
    <div className={className}>
      <div className="relative">
        {selectable && (
          <RadioButton position={selectionPosition} onChange={onChange} />
        )}
        <HrefLink href={href} onClick={onClick}>
          <div className="nft-img-div">
            {isVideo(nftCardData?.nftFileType) ? (
              <div className="nft-img">
                <VideoPlayer
                  url={nftCardData?.nftUrl}
                  urlThumbnail={nftCardData?.nftUrlThumbnail}
                />
              </div>
            ) : (
              <ImageHandler
                src={
                  nftCardData?.nftUrlThumbnail ||
                  nftCardData?.nftUrl ||
                  nftCardData?.nft?.image
                }
                alt={`Nft ${nftCardData.nftName}`}
                errorWidget={
                  <div className="nft-fallback-wrapper">
                    <div className="nft-fallback-content">
                      <div className="my-1 flex justify-center align-center">
                        <img
                          src={EmptyIcon}
                          width={52}
                          height={46}
                          alt="Empty Icon"
                          role="presentation"
                        />
                      </div>
                      <div className="my-1 nft-fallback-content-text">
                        Couldn’t load metadata
                      </div>
                      <div className="my-1 flex justify-center align-center">
                        <Button
                          loading={state.refreshing}
                          disabled={state.refreshing || state.refreshed}
                          className="user-profile-profile-btn shadow-xxs account-social-link-icon"
                          onClick={onReloadImage}
                        >
                          {state.refreshed ? 'Refreshed' : 'Refresh'}
                        </Button>
                      </div>
                    </div>
                  </div>
                }
              />
            )}
            {nftCardData?.nft?.auction && (
              <AuctionTimeLeftComponent
                expirationDate={nftCardData?.nft?.auctionEndedAt}
                isSmallCard
                auction={nftCardData?.nft}
                onAuctionEnd={onAuctionEnd}
              />
            )}
            {nftCardData.nft.isMysteryBox && (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => null}
                onClick={() => null}
              >
                <LockIcon className="lock-icon-nft-card lock-icon" />
              </div>
            )}
          </div>

          <div className="nft-card-bottom">
            <div className="row-between">
              <div className="nft-card-name truncate">
                {nftCardData.nftName}
              </div>
              {!hideUserAvatar && nftCardData.owner && (
                <UserAvatar
                  user={nftCardData.owner}
                  isNft
                  className="user-avatar-container item-card relative"
                />
              )}
            </div>
            <div className="card-bottom-price-likes">
              <div className="nft-card-price">
                <NFTCardPrice
                  openForSale={nftCardData.openForSale}
                  nft={nftCardData}
                />
              </div>

              {!nftCardData?.nft?.auction &&
              nftCardData.upvotesCount &&
              nftCardData.upvotesCount > 0 ? (
                <div className="list-likes-div">
                  <div className="like-count">
                    {formatNumber(nftCardData.upvotesCount || 0)}
                  </div>
                  <div className="like-icon-div">
                    <img
                      src={wasUpVoted() ? UpvoteIcon : NeutralVoteIcon}
                      alt="Like Icon"
                      role="presentation"
                      className="like-icon"
                    />
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </HrefLink>
      </div>
    </div>
  );
};
